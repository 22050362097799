import { UserPaymentsApi } from '../_services/api/userPayments.service';
import { BanksApi } from '../_services/api/banks.service';
/// <reference path="../../../../node_modules/@angular/core/src/metadata/lifecycle_hooks.d.ts"/>
import {Component, OnInit} from '@angular/core';
import {Page} from '../_services/page';
import {ActivatedRoute, Router} from '@angular/router';
import { AuthenticationService } from '../_services/api/authentication.service';
import { environment } from 'src/environments/environment';
import { PaymentMethod } from '../shared/models/paymentMethod';
import { Address } from '../shared/models/address';
import { PaymentMethodsApi } from '../_services/api/paymentMethods.service';
import {UserPayment} from '../shared/models/userPayment';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})

export class PaymentComponent implements OnInit {

  user: any = {};
  payment: PaymentMethod[] = [];
  paymentMethods: any[] = [];
  address: Address[] = [];
  defaultAddress: string;
  defaultpaymentMethod: string;
  baseUrl='';
  userPayment: any = {};
  userPaymentMethodId: any;
  requestAmount = 0;
  paymentMethodid: any;
  pendingPayment: any;
  historyTransactions: any[] = [];
  filter: any;
  payments: any [] = [];
  requestSubmitted = false;

  constructor(private PaymentsService: UserPaymentsApi,
              private route: ActivatedRoute,
              private router: Router,
              private authService : AuthenticationService,
              private banksApi : BanksApi,
              private paymentMethodApi: PaymentMethodsApi) {
                this.baseUrl= environment.baseUrl;
              }

  ngOnInit() {
    this.requestSubmitted  = false;
    this.userPayment = new UserPayment();
    this.authService.me().subscribe(async user => {
      console.log('user', JSON.stringify(user));
      this.user = user;
      if (this.user.address) {
        if (typeof this.user.address === 'string') {
          this.user.address = [{address: this.user.address, isDefault: false , }]
        }

        this.address = this.user.address || [];
        if (this.address && this.address.length > 0) {
          for (let addressLoop of this.address) {
            if (addressLoop.isDefault) {
              this.defaultAddress = addressLoop.address;
            }
          }
        }
      } else {
        this.user.address = [];
      }
      console.log('this.user', JSON.stringify(this.user));
      if (this.user.paymentMethods) {
        if (typeof this.user.paymentMethods === 'string') {
          this.user.paymentMethods = [{payment: this.user.paymentMethods, isDefault: false}]
        }
        this.paymentMethods = !!this.user && !!this.user.paymentMethods ?
        this.user.paymentMethods : undefined;
        console.log('paymentMethods', JSON.stringify(this.paymentMethods));
        this.payment = this.user.paymentMethods || [];
        if (this.payment && this.payment.length > 0) {
          for (let paymentLoop of this.payment) {
            if (paymentLoop.isDefault) {
              this.defaultpaymentMethod = paymentLoop.paymentMethodName;
            }
          }
        }
      } else {
        this.user.paymentMethods = [];
      }

      this.getMaxValueForRequest();
      this.loadPayments();
    });
    
  }

  floatOnly (event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode
    const value = event.target.value;
    const dotcontains = value.indexOf('.') !== -1;
    if (dotcontains) {
      if (charCode === 46) { return false; }
    }
    if (charCode === 46) { return true; }
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  async getPaymentFee(paymentMethod) {
    if (!paymentMethod) {
      return 0
    }
    try {
      if (paymentMethod && paymentMethod.bank && paymentMethod.bank.id) {
        const _bank = await this.banksApi.get(paymentMethod.bank.id).toPromise();
        if (_bank.transferFees) {
         return _bank.percentageFees ? _bank.transferFees / 100 * Number(this.userPayment.amount)
            : Number(_bank.transferFees);
        }
      }
      const _paymentMethod = await this.paymentMethodApi.get(paymentMethod.paymentMethodId).toPromise();
      if (_paymentMethod && _paymentMethod.transferFees) {
        return _paymentMethod.percentageFees ? (_paymentMethod.transferFees / 100) * Number(this.userPayment.amount)
          : _paymentMethod.transferFees
      }
      return 0
    } catch (error) {
    throw  error
    }
  }
  getMaxValueForRequest() {
    var requester = document.getElementById("amount");
    var currentItemAmount = this.user.accountBalance;
    requester.setAttribute('max', currentItemAmount.toString());
  }
  
  async submitRequest(){
    this.requestSubmitted = true;
    try {
      if(this.requestAmount > this.user.accountBalance) {
        this.requestAmount = this.user.accountBalance;
        
      }
        this.userPayment.amount = this.requestAmount ;
        
          const selectedpaymentMethod = this.paymentMethods.find(method => method.id === this.userPayment.id);
          
          this.userPayment.paymentMethod = selectedpaymentMethod;
          const fees = await this.getPaymentFee(selectedpaymentMethod)
          this.userPayment.paymentMethod.transferFees = fees;
          delete this.userPayment.id;
          this.userPayment.userId = this.user.id;
          this.userPayment.amount = Number(this.requestAmount)- Number(this.userPayment.paymentMethod.transferFees);
          await console.log('getPaymentFee', Number(this.getPaymentFee(this.userPayment.paymentMethod)));
        var userPaymentObject = new UserPayment();
        userPaymentObject.amount = userPaymentObject.totalAmount = this.userPayment.amount;
        userPaymentObject.userId = this.user.id;
        userPaymentObject.description = 'Created from web';
        userPaymentObject.paymentMethod = this.userPayment.paymentMethod;
        userPaymentObject.paymentStatusId = "5bf2a92a24291e3093d10f32";
        userPaymentObject.type = 'debit';
        userPaymentObject.isUserCreated = true;
        userPaymentObject.address = this.defaultAddress;
        await this.PaymentsService.save(userPaymentObject).toPromise();
        alert("Payemnt request added successfully!!");
        this.router.navigate(['/orders']);
      }
     catch (err) {
      
    }
  }

  async loadPayments() {
    //this.userId = this.authService.token.userId;
    this.filter = {
      where: {
        and: [
          {
            isDeleted: {neq: true}
          },
          {
            userId: this.user.id
          },
          {
            or: [
              {
                paymentStatusId: '5ae9d6ed4cb31d109851046d' // تحت التوصيل
              },
              {
                paymentStatusId: '5bf2a92a24291e3093d10f32' // مطلوب الدفع
              },
              {
                paymentStatusId: '5dbb1c0305722564f809ff6f' // تعذر الوصول للمستلم
              },
              {
                paymentStatusId: "60bb78b26806db7f0e312075" //قيد الدفع الالكتروني
              }
            ]
          }
        ]
      },
      include: ["paymentStatus", "user"],
      order: 'created DESC',
      limit: 1
    };

    await this.PaymentsService.query(this.filter).toPromise().then((resultPayment) => {
      this.payments = resultPayment.data;
    });
    if(!!this.payments && this.payments.length > 0){
      this.pendingPayment = this.payments[0];
    }
  }
}
