import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './_services/auth/auth-guard.service';
import { FinancialComponent } from './customer-financials/customer-financials.component';
import { TripUrlComponent } from './tripUrl/tripUrl.component';
import { ChartsComponent } from './charts/charts.component';
import { PaymentComponent } from './payment/payment.component';
import { ReturnsComponent } from './returns/returns.component';
import { SignupComponent } from './signup/signup.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent},
  {path: '', redirectTo: 'orders', pathMatch: 'full'},
  { path: 'orders',
   loadChildren: './orders/orders.module#OrdersModule',
   canActivate: [AuthGuard]
  },
  { path: 'financial', component: FinancialComponent,
   canActivate: [AuthGuard]},
  { path: 'trip/:id', component: TripUrlComponent},
  { path: 'charts', component: ChartsComponent,
  canActivate: [AuthGuard]},
  { path: 'payment-request', component: PaymentComponent,
   canActivate: [AuthGuard]},
   { path: 'returns', component: ReturnsComponent,
    canActivate: [AuthGuard]},
    { path: 'signup', component: SignupComponent}
];


@NgModule({
  imports: [RouterModule.forRoot(routes),
    RouterModule.forChild(routes)],

  exports: [RouterModule]
})
export class AppRoutingModule { }
