export class Page {
  // The number of elements in the page
  size = 10;
  // The total number of elements
  totalElements = 0;
  // The total number of pages
  totalPages = 0;
  // The current page number
  pageNumber = 0;

  include = [];

  where = {};
  order = [];

  constructor(page: any = null) {
    if (page) {
      this.totalElements = page['count'];
      if (!!page.size) {
        this.size = page.size;
        this.totalPages = Math.ceil(this.totalElements / page.size);
        this.pageNumber = (page['filter']['skip'] / page.size);
      } else {
        this.totalPages = Math.ceil(this.totalElements / this.size);
        this.pageNumber = (page['filter']['skip'] / this.size);
      }
      this.include = page['filter'].include;
      this.where = page['filter'].where;
      this.order = page['filter'].order;
    }
  }

}

