import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import { TripUrlResolver } from './tripUrl-resolver';
import { TripUrlComponent } from './tripUrl.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'received-trip/:id',
        component: TripUrlComponent,
        resolve: {
          trip: TripUrlResolver
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TripUrlRoutingModule {
}
