
export class UserPayment {
    public userId?: string;
    public amount?: number;
    public id?: string;
    public date?: Date;
    public description?: string;
    public isDeleted?: boolean = false;
    public type?: string;
    public driverId?: string;
    public deliveryDate?: Date;
    public deliveryTime?: Date;
    public paymentStatusId?: string;
    public address?: string;
    public location?: object;
    public paymentMethod?: object;
    public isUserCreated?: boolean;
    public totalAmount?: number;
    public user?: any;
    public documents? : any[];
  }
  