import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from 'rxjs';
import { BaseApiService } from '../baseAPI';
import { environment } from './../../../environments/environment';

@Injectable()
export class BookTripService extends BaseApiService<any> {

  constructor(http: HttpClient) {
    super(http);
    this.url = '/trips';
  }

  searchMyOrdersMobiles(filter: any) {
    console.log("hiii")
    return this.http.get(environment.baseUrl + this.url + '/searchMyMobiles?filter=' + JSON.stringify(filter))
  }

  upload(file: any): Observable<any> {
    console.log('amazon', file);
    let formData: FormData = new FormData();
    formData.append('file', file, file.name);
    let headers = new HttpHeaders();
    headers.append('path', file.name);

    let apiUrl = "/Containers/wassalnow/upload";

    return this.http.post(environment.baseUrl + apiUrl, formData)
      .map(m => {
        console.log('Success!!!', m['result'].files.file[0].providerResponse.location);
        return {
          path: m['result'].files.file[0].providerResponse.location,
          originalFilename: m['result'].files.file[0].originalFilename,
          name: m['result'].files.file[0].providerResponse.name

        }
      })
  }


}
