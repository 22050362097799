import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Trip} from '../../app/shared/models/trip';
import {TripsApi} from '../_services/api/trips.service';
import { map } from 'rxjs/operators';

@Injectable()
export class OrderResolver implements Resolve<any> {

  constructor(private api: TripsApi) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Trip> | Promise<Trip> | Trip | any {
    const id = route.paramMap.get('id');
    if (id === 'new') {
      return new Trip();
    }
    return this.api.query({where: {id: id}, include: ['pickupDriver', 'receiverDriver']}).pipe(map(trips => {
      return trips.data[0];
    }));
  }

}
