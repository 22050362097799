import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';


import {BaseApiService} from '../baseAPI';


@Injectable()
export class BanksApi extends BaseApiService<any> {

  constructor(http: HttpClient) {
    super(http);
    this.url = '/banks';
  }

}


