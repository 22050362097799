import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { navbarComponent } from './components/navbar/navbar';

@NgModule({
  imports: [CommonModule],
  declarations: [navbarComponent],
  exports: [navbarComponent]
})
export class SharedModule { }
