import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import { OrdersComponent } from '../orders/orders.component';
import { OrderDetailsComponent } from './orders-details/orders-details.component';
import { OrderResolver } from './order-resolver';
import { CreateOrderComponent } from './create-order/create-order.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: OrdersComponent,
        resolve: {
        },
        data: {
          title: 'Orders'
        }
      },
      {
        path: 'manage-order/:id',
        component: OrderDetailsComponent,
        resolve: {
          order: OrderResolver,
        },
        data: {
          title: 'Order Details'
        }
      },
      {
        path: 'create-order',
        component: CreateOrderComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OrdersRoutingModule {
}
