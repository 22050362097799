import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseApiService} from '../baseAPI';
import { Driver } from '../../shared/models/driver';
import {environment} from '../../../environments/environment';

@Injectable()
export class DriversApi extends BaseApiService<Driver> {

  constructor(http: HttpClient) {
    super(http);
    this.url = '/drivers';
  }

  getStats(id: string) {
    return this.http
      .get<any>(environment.baseUrl + this.url +  '/stats?id=' + `${id}`, {headers: this.authorization()})
  }

}


