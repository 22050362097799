import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';


import {BaseApiService} from '../baseAPI';
import { environment } from './../../../environments/environment';



@Injectable()
export class UsersApi extends BaseApiService<any> {

  constructor(http: HttpClient) {
    super(http);
    this.url = '/users';
  }

  getStats(id: string) {
    return this.http
      .get<any>(environment.baseUrl + this.url +  '/stats?id=' + `${id}`, {headers: this.authorization()})
  }

  getAddress(query){
    const sQuery = {
      limit: query.size,
      skip: query.size * query.pageNumber,
      order: query.order,
      include: query.include,
      where: query.where
    };
    return this.http
      .get<any>(environment.baseUrl + this.url + '/usersAddresses?filter=' + `${JSON.stringify(sQuery)}`, {headers: this.authorization()})

  }

}


