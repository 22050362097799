import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';


import {BaseApiService} from '../baseAPI';
import { Trip } from '../../shared/models/trip';


@Injectable()
export class TripsApi extends BaseApiService<Trip> {

  constructor(http: HttpClient) {
    super(http);
    this.url = '/trips';
  }

  saveReceiverPoint(tripId: any, receiverPoint: string, locationURL: string, location: any) {
    const data = {
      tripId,
      receiverPoint,
      locationURL,
      location
    }
    return this.http.post(this.baseUrl + this.url  +  `/save-receiver-point`, data, {headers: this.authorization()})
  }

  cancelShipment(shipmentId: any) {
    const data = {
      shipmentId
    }
    return this.http.post(this.baseUrl + this.url  +  '/webPortalCancelShipment', data, {headers: this.authorization()})
  }

  upload(req: any, res: any) {
    const data = {
     req,
     res
    };
    return this.http.post(this.baseUrl + this.url  +  `/upload`, data, {headers: this.authorization()});
  }

  shipmentsStatistics(date: Date) {
    return this.http
      .get(this.baseUrl + this.url + `/shipments-statistics?date=${date}`, {headers: this.authorization()})
  }
//GET /trips/userdaily-delivery-dtatistics
  userdailydeliverystatistics(date: Date, userId: String) {
    return this.http
      .get(this.baseUrl + this.url + `/userdaily-delivery-dtatistics?dropdate=${date}&userid=${userId}`, {headers: this.authorization()})
  }

  getUserIncomebyInterval(userId: string, dateFrom: Date) {
    const data = {
      userId,
      dateFrom
     };
    return this.http.post(this.baseUrl + this.url  +  `/get-user-income-by-interval`, data, {headers: this.authorization()})
  }

  getDeliveriesReturnsCount(userId: string, dateFrom: Date) {
    const data = {
      userId,
      dateFrom
     };
    return this.http.post(this.baseUrl + this.url  +  `/get-deliveries-return-count`, data, {headers: this.authorization()})
  }
  
}


