import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseApiService} from '../baseAPI';
import { of , Observable } from 'rxjs';
import { environment } from './../../../environments/environment';


@Injectable()
export class UserService extends BaseApiService<any> {

  currentUser: any;

  constructor(http: HttpClient) {
    super(http);
    this.url = '/users';
  }

  verify() {
    const currentUser = JSON.parse(localStorage.getItem('tokenProtal'));
    if (currentUser) {
      return this.get(currentUser['userId']);
    } else {
      return of(null);
    }
  }

  saveNotificationDevices(user: any) {
    const userSaving = {
      id: user.id,
      notificationDevices: user.notificationDevices
    };
    const token = JSON.parse(localStorage.getItem('tokenProtal'));
    return this.http.put(environment.baseUrl + this.url + '/updateNotificationDevices?access_token=' + token['id'],
      userSaving)
  }
}
