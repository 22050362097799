import { CitiesApi } from '../../_services/api/city.service';
import {Component, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {Router} from '@angular/router';
import { AuthenticationService } from '../../_services/api/authentication.service';
import {convertNumbers2English} from '../../shared/utils/utils';
import * as moment from 'moment';
import { BookTripService } from '../../_services/api/book-trip.service';
import { UserService } from '../../_services/api/users.service';
import { VacationsService } from '../../_services/api/vacations.service';

@Component({
  selector: 'app-create-order',
  templateUrl: './create-order.component.html',
  styleUrls: ['./create-order.component.scss']
})
export class CreateOrderComponent implements OnInit {
  @ViewChild('f', {static: false}) createOrder: NgForm;
  returnUrl = '';
  wrongCredintials = false;
  cities: any;
  shipmentType: any;
  userId: any;
  user: any;
  addressDetailsForm: any;
  paymentMethod: any;
  shipmentPrice: any;
  pickedupCity: any;
  pickupTime: any;
  estimatedDropTime: any;
  trip: any = {};
  pickupCityId: any;
  pickupMobile: any;
  mobile: any;
  pickupHomeTel: any;
  receiverHomeTel: any;
  addresses: any;
  newAddress: any;
  vacations = [];
  days = [];
  intervals = [];
  minDate = new Date();
  filter: any;
  userMobile: any;
  accountUser: any;
  noUserFound = false;
  disable = false;
  constructor( private router: Router,
               private authService: AuthenticationService,
               public tripService: BookTripService,
               private citiesService: CitiesApi,
               private userService: UserService,
               private vacationApi: VacationsService
    ) {
      this.citiesService.query({
        where: {and: [{isDeleted: {neq: true}}]}
      }).toPromise().then((cities) => {
        this.cities = cities.data;
      });
  }

  ngOnInit() {
    this.loadVacations();
    this.loadData();
  }

  async loadVacations() {
    try {
      await this.vacationApi.query({
        where: {isDeleted: {neq: true}}
      }).toPromise().then((vacations: any) => {
        if (vacations) {
          this.vacations = vacations.data;
          const today = new Date()
          const tomorrow = new Date(today)
          tomorrow.setDate(tomorrow.getDate())
          let tomorrowVacation = {
              "type": 'interval',
              "dateFrom": today,
              "dateTo": today
          };
          this.vacations.push(tomorrowVacation);

          if (!!this.vacations && this.vacations.length > 0) {
            this.vacations.forEach(vacation => {
              if (vacation.type === 'day') {
                this.days.push(vacation.vacationDay);
              } else {
                vacation.dateFrom = moment(vacation.dateFrom).set({hour: 0, minute: 0, second: 0, millisecond: 0});
                vacation.dateTo = moment(moment(vacation.dateTo)).set({hour: 23, minute: 59, second: 59, millisecond: 999});
                this.getDates(vacation.dateFrom, vacation.dateTo);
              }
            });
          }
        }
      }, (err) => {
        console.log('Error =>', err);
      });
     } catch (err) {
        console.log(err);
     }
  }
  getDates(startDate, stopDate) {
    const dateArray = [];
    while (startDate <= stopDate) {
        dateArray.push( startDate);
        startDate = moment(startDate).add(1, 'days');
    }
    this.intervals = dateArray;
  }

  loadData() {
    if (!this.authService.token) {
      return;
    }
    this.userId = this.authService.token.userId;
    this.authService.get(this.authService.token.userId).subscribe((res) => {
      this.user = res;
      this.userMobile = res.mobile;
      this.accountUser = res;
      if (this.user && this.userId) {
        this.trip.pickupCustomerName = this.user.fullName;
      }
      if (this.user.address && this.user.address.length > 0) {
        this.addresses = this.user.address ;
        const defaultAdd = this.addresses.filter(add => add.isDefault === true);
        if (!!defaultAdd && defaultAdd.length > 0) {
          if (defaultAdd[0].address) {
            this.trip.startPoint = defaultAdd[0].address;
          }
          if (defaultAdd[0].cityId) {
            this.trip.pickupCityId = defaultAdd[0].cityId;
          }
        } else {
          if (this.addresses[0].address) {
            this.trip.startPoint = this.addresses[0].address;
          }
          if (this.addresses[0].cityId) {
            this.trip.pickupCityId = this.addresses[0].cityId;
          }
        }
      }
      if (this.user && this.user.mobile) {
        this.pickupMobile = this.user.mobile;
      }
    });
  }

  searchCurrentMobile() {
    if (!!this.mobile) {
      if (this.mobile === this.userMobile) {
        this.showAlert(this.accountUser);
        return;
      }
      const filter = {
        mobile: this.mobile,
        userId: this.authService.token.userId
      };
      this.tripService.searchMyOrdersMobiles(filter).subscribe((res: any) => {
        if (!!res && res.user) {
          this.showAlert(res.user);
        } else {
          alert('this user does not exist ');
        }
      });
    }
  }
  showAlert(receiver) {
    if (receiver && receiver.id) {
    this.trip.receiverCustomerId = receiver.id;
    this.trip.fullName = receiver.fullName;
    }
    if (receiver.address && receiver.address.length > 0) {
      this.addresses = receiver.address;
      const defaultAdd = receiver.address.filter(add => add.isDefault === true);
      if (!!defaultAdd && defaultAdd.length > 0) {
        if (defaultAdd[0].address) {
          this.trip.receiverPoint = defaultAdd[0].address;
        }
        if (defaultAdd[0].cityId) {
          this.trip.receiverCityId = defaultAdd[0].cityId;
        }
      } else {
        if (receiver.address[0].address) {
          this.trip.receiverPoint = receiver.address[0].address;
        }
        if (receiver.address[0].cityId) {
          this.trip.receiverCityId = receiver.address[0].cityId;
        }
      }
    }
    if (receiver && receiver.mobile) {
    this.pickupMobile = this.user.mobile;
    }
  }

  searchPickupMobile() {
    if (!!this.pickupMobile) {
      if (this.pickupMobile === this.userMobile) {
        this.noUserFound = false;
        this.showPickupAlert(this.accountUser);
        return;
      }
      const filter = {
        mobile: this.pickupMobile,
        userId: this.authService.token.userId
      };
      this.tripService.searchMyOrdersMobiles(filter).subscribe((res: any) => {
        if (!!res && res.user) {
          this.noUserFound = false;
          this.showPickupAlert(res.user);
        } else {
          this.noUserFound = true;
          alert('this user does not exist ');
        }
      });
    }
  }
  showPickupAlert(pickupUser) {
    this.user = pickupUser;
    this.userId = this.user.id;
    this.trip.pickupCustomerId = pickupUser.id;
    this.trip.pickupCustomerName = pickupUser.fullName;
    if (pickupUser.address && pickupUser.address.length > 0) {
      this.addresses = pickupUser.address;
      const defaultAdd = pickupUser.address.filter(add => add.isDefault === true);
      if (!!defaultAdd && defaultAdd.length > 0) {
        if (defaultAdd[0].address) {
          this.trip.startPoint = defaultAdd[0].address;
        }
        if (defaultAdd[0].cityId) {
          this.trip.pickupCityId = defaultAdd[0].cityId;
        }
      } else {
        if (pickupUser.address[0].address) {
          this.trip.startPoint = pickupUser.address[0].address;
        }
        if (pickupUser.address[0].cityId) {
          this.trip.pickupCityId = pickupUser.address[0].cityId;
        }
      }
    }
    if (pickupUser && pickupUser.mobile) {
    this.pickupMobile = this.user.mobile;
    }
  }

  onBlurMobile() {
    if (!!this.pickupMobile) {
      if (this.pickupMobile !== this.user.mobile) {
        this.noUserFound = true;
      } else {
        this.noUserFound = false;
      }
    } else {
      this.noUserFound = true;
    }
  }

  AddNewAddress() {
    const add: any = {};
    add.address = this.newAddress;
    add.cityId = this.pickupCityId;
    add.cityName = this.pickedupCity;
    this.user.address.push(add);
    this.userService.save(this.user).subscribe( res => {
    this.addresses = res.address;
    this.newAddress = '';
    this.trip.startPoint = res.address[res.address.length - 1].address;
    });
  }

  // converthinditoarabicnumbers(convertNumbers2English)

  // convertnumber() {
  //   var x = document.getElementById("mobileinput");
  //   x.value = parseArabic(x.value);
  // }

  parseArabic() {
    return String( this.mobile.replace(/[٠١٢٣٤٥٦٧٨٩]/g, function(d) {
        return d.charCodeAt(0) - 1632; // Convert Arabic numbers
    }).replace(/[۰۱۲۳۴۵۶۷۸۹]/g, function(d) {
        return d.charCodeAt(0) - 1776; // Convert Persian numbers
    }) );
  }

  async saveTrip(ngForm) {
      this.disable = true;
      if (!!this.mobile) {
        this.filter = {where: {mobile: this.mobile}};
        const data = await this.authService.query(this.filter).toPromise();
        const res = data.data;
        if (!!res && res.length > 0) {
          this.trip.receiverCustomerId = res[0].id;
          this.trip.fullName = res[0].fullName;
          if (!!res[0].address && res[0].address.length > 0) {
            if (res[0].address.filter(addr => addr.address === this.trip.receiverPoint).length < 1) {
              res[0].address.push({address: this.trip.receiverPoint, isDefault: false});
            }
          } else {
            res[0].address = [{address: this.trip.receiverPoint, isDefault: false}];
          }
          if(!!this.receiverHomeTel) {
            res[0].mobile2 = this.receiverHomeTel;
          }
          this.authService.update(res[0]).subscribe();
        } else {
          const newUser = {
            username: this.mobile,
            cityId: this.trip.receiverCityId,
            mobile: this.mobile,
            mobile2 : this.receiverHomeTel,
            fullName: this.trip.fullName,
            address: [{address: this.trip.receiverPoint, isDefault: true}],
            isCreatedAuto: true,
          };
          const user = await this.authService.add(newUser).toPromise();
          this.trip.receiverCustomerId = user.id;
        }
      }
      if (!!this.pickupMobile) {
        this.filter = {where: {mobile: this.pickupMobile}};
        const data = await this.authService.query(this.filter).toPromise();
        const res = data.data;
        if (!!res && res.length > 0) {
          this.userId = res[0].id;
          this.user = res[0];
          if (!!res[0].address && res[0].address.length > 0) {
            if (res[0].address.filter(addr => addr.address === this.trip.startPoint).length < 1) {
              res[0].address.push({address: this.trip.startPoint, isDefault: false});
            }
          } else {
            res[0].address = [{address: this.trip.startPoint, isDefault: false}];
          }
          this.authService.update(res[0]).subscribe();
        } else {
          const newUser = {
            username: this.pickupMobile,
            cityId: this.trip.pickupCityId,
            mobile: this.pickupMobile,
            fullName: this.trip.pickupCustomerName,
            address: [{address: this.trip.startPoint, isDefault: true}],
            isCreatedAuto: true
          };
          const user = await this.authService.add(newUser).toPromise();
          this.userId = user.id;
          this.user = user;
        }
      }
      this.trip.userId = this.userId;
      this.trip.pickupCustomerId = this.userId;
      this.trip.estimatedPickupTime = !!this.pickupTime ? moment(this.pickupTime).format().toString() : null;
      this.trip.estimatedDropTime = !!this.estimatedDropTime ? moment(this.estimatedDropTime).format().toString() : null;
      this.trip.shipmentPrice = convertNumbers2English(this.shipmentPrice);
      this.trip.paymentMethod = this.paymentMethod;
      this.trip.shipmentType = this.shipmentType;
      this.trip.mobile = convertNumbers2English(this.pickupMobile);
      this.trip.receiverMobile = convertNumbers2English(this.mobile);
      this.trip.pickupHomeTel = convertNumbers2English(this.pickupHomeTel);
      this.trip.receiverHomeTel = convertNumbers2English(this.receiverHomeTel);
      this.trip.platform = 'web';
      this.trip.orderStatusId = '5ae9d6ed4cb3dd109851046d';
      if(this.paymentMethod === "Deduct"){
        this.trip.senderPays = this.trip.deductFromSender = true;
      }
      else if (this.paymentMethod === 'I Will Pay'){
        this.trip.senderPays = true;
        this.trip.deductFromSender = false;
      }
      else{
        this.trip.senderPays = this.trip.deductFromSender = false;
      }

      this.tripService.save(this.trip).subscribe(() => {
        this.disable = false;
        ngForm.reset();
        this.router.navigate(['/orders']);
      }, (err) => {
        console.error('err ==> ', err)
        this.disable = false;
        alert('Error while creating order');
      });
  }
}
