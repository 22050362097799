import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../_services/api/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  user: any;
  showVerificationModal = false;
  inputElements: any;
  wrongCredintials: boolean = false;

  constructor(
    private router: Router,
    public _authService: AuthenticationService,
  ) { }

  ngOnInit(): void {

   this.inputElements = document.getElementsByClassName("code-input");
   
   this.inputElements.forEach((input, index1) => {
    input.addEventListener("keyup", (e) => {
      // This code gets the current input element and stores it in the currentInput variable
      // This code gets the next sibling element of the current input element and stores it in the nextInput variable
      // This code gets the previous sibling element of the current input element and stores it in the prevInput variable
      const currentInput = input,
        nextInput = input.nextElementSibling,
        prevInput = input.previousElementSibling;
  
      // if the value has more than one character then clear it
      if (currentInput.value.length > 1) {
        currentInput.value = "";
        return;
      }
      // if the next input is disabled and the current value is not empty
      //  enable the next input and focus on it
      if (nextInput && nextInput.hasAttribute("disabled") && currentInput.value !== "") {
        nextInput.removeAttribute("disabled");
        nextInput.focus();
      }
  
      // if the backspace key is pressed
      if (e.key === "Backspace") {
        // iterate over all inputs again
        this.inputElements.forEach((input, index2) => {
          // if the index1 of the current input is less than or equal to the index2 of the input in the outer loop
          // and the previous element exists, set the disabled attribute on the input and focus on the previous element
          if (index1 <= index2 && prevInput) {
            input.setAttribute("disabled", true);
            input.value = "";
            prevInput.focus();
          }
        });
      }
      
    });
  });
  }
  


  async onSubmit(ngForm) {
    this.user = {
      fullName: ngForm.value.fullname,
      mobile: ngForm.value.phone,
      password: ngForm.value.password,
      address: [],
      platform: 'web'
    };
    console.log('this.user', this.user);
    // loading.present().then();
    try {
      await this._authService.addNewUser(this.user);

      await this._authService.sendCode(this.user.mobile).toPromise();
      this.showVerificationModal = true;
      
    } catch (err) {
      console.log(err);
        try{
          await this._authService.sendCode(this.user.mobile).toPromise();
          this.showVerificationModal = true;
        } catch (error){
          console.log('user was already exist but error occured at send verification code', error);
        }
    }
  }

  async onVerificationSubmit(e){
    let formCode = '' + e.value.code1 + e.value.code2 + e.value.code3 + e.value.code4 + e.value.code5;
    console.log('formCode', formCode);

    this.user['passcode'] = formCode;
    //this.user.passcode = formCode;
    this._authService.VerifyCode(this.user).subscribe((res) => {
      this.wrongCredintials = false;
      alert("User Verified successfully");
      this.router.navigate(['/login']);
      },
      () => {
        this.wrongCredintials = true;
      });

  }

}
