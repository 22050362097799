import {HttpClient, HttpHeaders} from "@angular/common/http";

import {BaseModel} from "./base-model";
import {environment} from "../../environments/environment";
import {Observable} from 'rxjs';
import {QueryResult} from './query-result';

export class BaseApiService<T extends BaseModel> {
  public baseUrl = environment.baseUrl;
  public url = "/";
  protected http: HttpClient;

  constructor(private httpClient: HttpClient) {
    this.http = this.httpClient;
  }

  public get authToken(): string {
    return (JSON.parse(localStorage.getItem('tokenProtal'))) ?
      (JSON.parse(localStorage.getItem('tokenProtal'))).id : null;
  }

  protected authorization(token_1?: any): HttpHeaders {
    let token = (JSON.parse(localStorage.getItem('tokenProtal'))) ?
      (JSON.parse(localStorage.getItem('tokenProtal'))).id : null;
    if (token_1 && !token) {
      token = token_1;
    }
    let currentCountry = (JSON.parse(localStorage.getItem('currentCountry'))) ?
      (JSON.parse(localStorage.getItem('currentCountry'))).id : null;

    if (token) {
      if (currentCountry)
        return new HttpHeaders()
          .set("Content-Type", "application/json")
          .set("Accept", "application/json")
          .set("country_id", currentCountry.toString())
          .set('Authorization', token);
      else
        return new HttpHeaders()
          .set("Content-Type", "application/json")
          .set("Accept", "application/json")
          .set('Authorization', token);
    } else {
      if (currentCountry)
        return new HttpHeaders()
          .set("Content-Type", "application/json")
          .set("Accept", "application/json")
          .set("country_id", currentCountry.toString());
      else
        return new HttpHeaders()
          .set("Content-Type", "application/json")
          .set("Accept", "application/json");
    }

  }

  list() {
    return this.http
      .get<T>(this.baseUrl + this.url, {headers: this.authorization()})
  }

  get(id: string) {
    return this.http
      .get<T>(this.baseUrl + this.url + `/${id}`, {headers: this.authorization()})
  }

  query(query: any) {
    const sQuery = {
      limit: query.size,
      skip: query.size * query.pageNumber,
      order: query.order,
      include: query.include,
      where: query.where
    };
    return this.http
      .get<QueryResult<T>>(environment.baseUrl + this.url +
        '/search?filter=' + `${JSON.stringify(sQuery)}`
        , {headers: this.authorization()})
  }

  save(item: T) {
    return item.id ? this.update(item) : this.add(item);
  }

  add(item: T) {
    return this.http.post<T>(this.baseUrl + this.url, item, {headers: this.authorization()})
  }

  update(item: T) {
    return this.http.patch<T>(this.baseUrl + this.url + `/${item.id}`, item, {headers: this.authorization()})
  }

  destroy(id: String): any {
    return this.http
      .delete<T>(this.baseUrl + this.url + `/${id}`, {headers: this.authorization()})

  }

  queryByObject(query: any) {

    return this.http
      .get<T>(environment.baseUrl + this.url + '?filter=' + `${JSON.stringify(query)}`, {headers: this.authorization()})
  }

  uploadImage(image: File): Observable<any> {

    const formData: FormData = new FormData();
    formData.append('image', image);
    const headers = new HttpHeaders();
    //headers.append('path', file.name);
    const apiUrl = '/Containers/wassalnow/upload';

    return this.http.post(environment.baseUrl + apiUrl, formData)
      .map(m => {
        return {
          path: m['result'].files.file[0].providerResponse.location,
          originalFilename: m['result'].files.file[0].originalFilename,
          name: m['result'].files.file[0].providerResponse.name
        }
      })
  }


  /*upload(file: any): Observable<any> {
    console.log('amazon', file);
    let formData: FormData = new FormData();
    formData.append('file', file, file.name);
    let headers = new HttpHeaders();
    headers.append('path', file.name);

    let apiUrl = "/Containers/alnafisah.app/upload";

    return this.http.post(this.baseUrl + apiUrl, formData)
      .map(m => {
        console.log('Success!!!', m['result'].files.file[0].providerResponse.location);
        return {
          path: m['result'].files.file[0].providerResponse.location,
          originalFilename: m['result'].files.file[0].originalFilename,
          name: m['result'].files.file[0].providerResponse.name

        }
      })
  }*/

  /*uploadS3(fileTransfer: FileTransferObject, file: any): Promise<any> {
    let options: FileUploadOptions = {
      fileKey: 'alnafisahImageFile',
      fileName: 'alnafisahImageFile',
      chunkedMode: false,
      mimeType: "image/jpeg",
      headers: {}
    };
    let apiUrl = "/Containers/alnafisah.app/upload";
    return fileTransfer.upload(file, this.baseUrl + apiUrl, options)
      .then((data) => {
        let response = JSON.parse(data.response);
        console.log("response: " + response);
        return {
          path: response.result.files.alnafisahImageFile[0].providerResponse.location,
          originalFilename: response.result.files.alnafisahImageFile[0].originalFilename,
          name: response.result.files.alnafisahImageFile[0].providerResponse.name
        };
      }, (err) => {
        return {
          error: err
        }
      });
  }*/

}
