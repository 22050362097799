import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';


import {BaseApiService} from '../baseAPI';
import { PaymentMethod } from '../../shared/models/paymentMethod';


@Injectable()
export class PaymentMethodsApi extends BaseApiService<PaymentMethod> {

  constructor(http: HttpClient) {
    super(http);
    this.url = '/paymentMethods';
  }

}


