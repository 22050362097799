import { environment } from './../../environments/environment';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import {Trip} from '../shared/models/trip';
import { OrderStatus } from "../shared/models/orderStatus";
import {TripsApi} from '..//_services/api/trips.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../_services/api/authentication.service';
import * as moment from 'moment';
import { Address } from '../shared/models/address';
import { PaymentMethodsApi } from '../_services/api/paymentMethods.service';
import { PaymentMethod } from '../shared/models/paymentMethod';
import {City} from '../shared/models/city';
import * as uuid from 'uuid';
import { CitiesApi } from '../_services/api/city.service';
import { FileUploader, FileItem } from 'ng2-file-upload';
import { BanksApi } from '../_services/api/banks.service';
import { OrderStatusesApi } from '../_services/api/orderStatuses.service';
import { values } from "lodash";
import { VacationsService } from '../_services/api/vacations.service';
import { BookTripService } from '../_services/api/book-trip.service';
import {convertNumbers2English} from '../shared/utils/utils';
import {Page} from '../_services/page';


const defaultLocation = {
  lat: 37.97589393307724,
  lng: 23.66287293749997
}

const URL = environment.baseUrl + '/trips/upload';
@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {
  @ViewChild('uploadInput',{static: false}) uploadInputVariable: ElementRef;
  trip: Trip[];
  pickupRequest: any = {};;
  submitted: boolean = false;
  orders: any=[];
  filter: any;
  itemsFilter: {};
  userId: any;
  user: any = {};
  vacations = [];
  days = [];
  intervals = [];
  minDate = new Date();
  address: Address[] = [];
  payment: PaymentMethod[] = [];
  paymentMethods: any[] = [];
  methods: any;
  paymentMethod = {
    paymentMethodId: '',
    paymentMethodName: '',
    accountNumber: null ,
    bankId: null,
    beneficiaryName : null
  }
  newAddress = {
    address: '',
    location: {}
  };
  cities:any;
  options: any = {};
  lat: number = defaultLocation.lat;
  lng: number = defaultLocation.lng;
  addressAlreadyExist = false;
  locationURL: any = `http://maps.google.com/?q=${this.lat},${this.lng}`;
  editingAddress = false;
  paymentMethodAlreadyExist = false;
  paymentMethodIsEmpty = false;
  editingPaymentMethod = false;
  show_dialog : boolean = false;
  show_dialog2 : boolean = false;
  show_PickupRequestDialog : boolean = false;
  displayUploadModal:string;
  uploadResult:any;
  uploadError:any;
  public imagePath;
  display: string;
  defaultAddress: string;
  defaultpaymentMethod: string
  display2: string;
  isFileUploading=false;
  uploader: FileUploader;
  uploadedFileName:string;
  baseUrl='';
  methodIsBank: boolean;
  banks = [];
  formHasError: boolean;
  numberofshipments: '';

  orderStatuses: any;
  defaultOrder: string[] = ["created DESC"];
  sortOrder = { order: this.defaultOrder };
  defaultInclude: string[] = [
    "orderStatus", 
    "receiverCustomer"
  ];
  searchFilter = {
    include: this.defaultInclude,
    where: { and: [], or: [] },
  };

  searchBar = false;
  profileVisible = false;
  profileSideHeight = 340;
  filterData: any;

  trackNo = "";
  orderStatusId = "";

  pickupRequestDate: any;
  printAWBTrackNos : string[] = [];
  offset = 0;
  limit = 10;
  public rows = [];
  public page = new Page();

  ranges: any = {
    Today: [moment(), moment()],
    'Tomorrow': [moment().add(1, "days"), moment().add(1, "days")],
    Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
    "Last 7 Days": [moment().subtract(6, "days"), moment()],
    "Last 30 Days": [moment().subtract(29, "days"), moment()],
    "This Month": [moment().startOf("month"), moment().endOf("month")],
    "Last Month": [
      moment().subtract(1, "month").startOf("month"),
      moment().subtract(1, "month").endOf("month"),
    ],
    "This Year": [moment().startOf("year"), moment().endOf("year")],
    "Last Year": [
      moment().subtract(1, "year").startOf("year"),
      moment().subtract(1, "year").endOf("year"),
    ],
  };


  toggleSearchBar() {
    this.searchBar = !this.searchBar;
  }

  toggleProfile(){
    this.profileVisible = !this.profileVisible;
    this.profileSideHeight = this.profileVisible ? 600 : 340;
    console.log ('this.profileSideHeight' , this.profileSideHeight);
  }

  constructor(private tripsServeices: TripsApi,
    private router: Router,
    private route: ActivatedRoute,
    public authService: AuthenticationService,
    public citiesApi : CitiesApi,
    public banksApi : BanksApi,
    private paymentMethodApi: PaymentMethodsApi,
    public orderStatusesApi : OrderStatusesApi,
    private vacationApi: VacationsService,
    public tripService: BookTripService) {
      this.uploader = new FileUploader({url: URL, isHTML5: true, authToken: authService.authToken });
      this.uploader.onErrorItem = (item, response, status ,  headers) => this.onErrorItem(item, response, status);
      this.uploader.onSuccessItem = (item, response, status , headers ) => this.onSuccessItem(item, response, status);
      this.baseUrl= environment.baseUrl;
    }


handleFocus(): void {
  setTimeout(() => {
     const myCustomClass: string ="custom-class"
     const panel = document.querySelector('.ng-dropdown-panel');
     panel.classList.add(myCustomClass);
     console.log('panel', panel);
   }, 10);
}

  async ngOnInit() {
    this.authService.me().subscribe(async user => {
      this.user = user;
      if (this.user.address) {
        if (typeof this.user.address === 'string') {
          this.user.address = [{address: this.user.address, isDefault: false , }]
        }

        this.address = this.user.address || [];
        if (this.address && this.address.length > 0) {
          for (let addressLoop of this.address) {
            if (addressLoop.isDefault) {
              this.defaultAddress = addressLoop.address;
            }
          }
        }
      } else {
        this.user.address = [];
      }

      if (this.user.paymentMethods) {
        if (typeof this.user.paymentMethods === 'string') {
          this.user.paymentMethods = [{payment: this.user.paymentMethods, isDefault: false}]
        }

        this.payment = this.user.paymentMethods || [];
        if (this.payment && this.payment.length > 0) {
          for (let paymentLoop of this.payment) {
            if (paymentLoop.isDefault) {
              this.defaultpaymentMethod = paymentLoop.paymentMethodName;
            }
          }
        }
      } else {
        this.user.paymentMethods = [];
      }

    });
    this.methods = await this.paymentMethodApi.list().toPromise();
    !this.itemsFilter ? this.itemsFilter = {} : {};
    this.userId = this.authService.token.userId;
    this.setPage(0).then();

    this.orderStatuses = await this.orderStatusesApi.list().toPromise();

    this.cities = await this.citiesApi.list().toPromise();
    this.banks =  await this.banksApi.list().toPromise();

    this.loadVacations();
  }



  async orderStatusSelected(event: any) {

    if (event == null || event.length == 0) {
      delete this.itemsFilter["orderStatusId"];
    } else {
      this.itemsFilter["orderStatusId"] = {
        orderStatusId:{inq: event},
      };
      this.orderStatusId = event;
    }
    this.setPage(0);

  //if (!!this.isDelayedTrips) {
    localStorage.setItem("TripFilter", JSON.stringify(this.itemsFilter));
  //}
  //await this.setPage({ offset: 0 });
}



async onChangeTrackNo(event: any) {
  if (event.keyCode === 13) {
    await this.trackNoCheck();
  }
}

async trackNoCheck() {

  this.trackNo.length > 0
    ? (this.itemsFilter["trackNo"] = {
        trackNo: {
          like: this.trackNo,
          options: "i",
        },
      })
    : delete this.itemsFilter["trackNo"];
      localStorage.setItem("tripFilter", JSON.stringify(this.itemsFilter));
    await this.setPage(0);
}

async search() {
  await this.trackNoCheck();
}

async reset(){
  localStorage.removeItem("TripFilter");
  this.trackNo = "";
  delete this.itemsFilter["trackNo"];

  //this.orderStatusReset = [];
  this.orderStatusId = "";
  delete this.itemsFilter["orderStatusId"];
  await this.setPage(0);
}

// loadMore() {
//   this.http.get(`http://your-api-endpoint.com/items?offset=${this.offset}&limit=${this.limit}`)
//     .subscribe((data) => {
//       this.items = this.items.concat(data);
//       this.offset += this.limit;
//     });
//   }

loadMore() {
  this.offset += 1;
  this.setPage(this.offset);
}

  async loadOrders() {
    this.userId = this.authService.token.userId;
    
    this.filter = {
      where: {
        and: [
          {
            isDeleted: {neq: true}
          },
        {
        or: [
          {
            pickupCustomerId: this.userId
          }
        ]
        }
        ]
      },
      include: ["orderStatus", "receiverCustomer"],
      order: 'created DESC',
      limit: this.limit,
      skip: this.offset
    };
    
    //!this.itemsFilter ? this.itemsFilter = {} : {};
    
    //this.filter.order = this.sortOrder.order;
    // this.itemsFilter["userId"] = {
    //   or: [
    //     {
    //       pickupCustomerId: this.userId
    //     }
    //   ]
    // };
    // this.itemsFilter["isDeleted"] = {
    //   isDeleted:{neq: true},
    // };
    
    //this.filter.where.and = values(this.itemsFilter);

    // if (this.filter.where.and.length <= 0) {
    //   delete this.filter.where.and;
    // }
    console.log('this.filter', this.filter);
    const data = await this.tripService.query(this.filter).toPromise();
    this.orders = this.orders.concat(data.data);
    
  }


  async setPage(offset) {
    this.user = await this.authService.me().toPromise();
    this.page.pageNumber = offset;
    this.page.order = ['created desc'];
    this.page.size = 20;
    this.page.where = {
      and: [
        {
          isDeleted: {neq: true}
        },
        {
            pickupCustomerId: this.user.id
        },
        {
          orderStatusId : !!this.itemsFilter["orderStatusId"] ? { inq: this.orderStatusId } : {neq: null}
        },
        {
          trackNo : !!this.itemsFilter["trackNo"] ? { like: this.trackNo, options: "i" } : {neq: null}
        }
      ]
    },
    this.tripService.query(this.page)
      .subscribe(pagedData => {
         if(offset == 0){
          this.offset = 0;
           this.orders = pagedData.data;
         } else {
          this.orders = this.orders.concat(pagedData.data);
         }
        pagedData['size'] = 20;
        this.page = new Page(pagedData)
      });
      console.log(this.page);
  }

  viewTrans(){
    this.router.navigate(['/financial']);
  }
  viewCharts(){
    this.router.navigate(['/charts']);
  }
  viewPaymentRequest(){
    this.router.navigate(['/payment-request']);
  }
  preview(files) {
    var reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.user.image = reader.result;
      this.authService.save(this.user).subscribe();
    }
  }
  openModalDialog() {
    this.display = 'block';
 }
 openModalDialog2() {
  this.display2 = 'block';
}

 closeModalDialog(){
  this.display ='none';
 }
 closeModalDialog2(){
  this.display2 ='none';
 }
 setDefaultAddress(address: any) {
  this.user.address.forEach(add => {
    add.isDefault = false;
  })
  address['isDefault'] = true;
  this.user.address = this.user.address.filter(add => add.address !== address.address);
  this.user.address.push(address);
  this.defaultAddress = address.address;
  this.closeModalDialog()
  this.authService.save(this.user).subscribe();
}
setDefaultPayment(paymentMethod: any){
  this.user.paymentMethods.forEach(add => {
    add.isDefault = false;
  })
  paymentMethod['isDefault'] = true;
  this.user.paymentMethods = this.user.paymentMethods.filter(add => add.paymentMethodName !== paymentMethod.paymentMethodName);
  this.user.paymentMethods.push(paymentMethod);
  this.defaultpaymentMethod = paymentMethod.paymentMethodName;
  this.closeModalDialog2()
  this.authService.save(this.user).subscribe();
}

toggle() {
  this.show_dialog = !this.show_dialog;
  this.show_PickupRequestDialog = this.show_dialog2 = false
}
toggle2() {
  this.show_dialog2 = !this.show_dialog2;
  this.show_PickupRequestDialog = this.show_dialog = false

}
togglePickupRequest(){

  this.show_PickupRequestDialog = !this.show_PickupRequestDialog;
  this.show_dialog = this.show_dialog2 = false;
  console.log("show_PickupRequestDialog: " + this.show_PickupRequestDialog);
}

isAddressAlreadyExist(address: any) {
  return (this.user.address && this.user.address.filter(add => add.address === address.address).length > 0)
}

getDates(startDate, stopDate) {
  const dateArray = [];
  while (startDate <= stopDate) {
      dateArray.push( startDate);
      startDate = moment(startDate).add(1, 'days');
  }
  this.intervals = dateArray;
}

async loadVacations() {
  try {
    await this.vacationApi.query({
      where: {isDeleted: {neq: true}}
    }).toPromise().then((vacations: any) => {
      if (vacations) {
        this.vacations = vacations.data;
        const today = new Date()
        const tomorrow = new Date(today)
        tomorrow.setDate(tomorrow.getDate())
        let tomorrowVacation = {
            "type": 'interval',
            "dateFrom": today,
            "dateTo": today
        };
        this.vacations.push(tomorrowVacation);

        if (!!this.vacations && this.vacations.length > 0) {
          this.vacations.forEach(vacation => {
            if (vacation.type === 'day') {
              this.days.push(vacation.vacationDay);
            } else {
              vacation.dateFrom = moment(vacation.dateFrom).set({hour: 0, minute: 0, second: 0, millisecond: 0});
              vacation.dateTo = moment(moment(vacation.dateTo)).set({hour: 23, minute: 59, second: 59, millisecond: 999});
              this.getDates(vacation.dateFrom, vacation.dateTo);
            }
          });
        }
        console.log('days disabled: ', JSON.stringify(this.days));
      }
    }, (err) => {
      console.log('Error =>', err);
    });
   } catch (err) {
      console.log(err);
   }
}

createNewPickupRequest(){
  
     if(!!this.user && !!this.pickupRequestDate){
     try{
      this.pickupRequest.userId = this.userId;
      console.log('this.pickupRequest.userId = this.userId;', this.userId);
      this.pickupRequest.pickupCustomerId = this.userId;

      this.pickupRequest.estimatedPickupTime = !!this.pickupRequestDate ? moment(this.pickupRequestDate).format().toString() : null;

      this.pickupRequest.shipmentPrice = '0';
      this.pickupRequest.mobile = convertNumbers2English(this.user.mobile);
      console.log('this.pickupRequest.mobile', this.pickupRequest.mobile);

      //this.pickupRequest.receiverMobile = convertNumbers2English(wassalnow_account.mobile);
      this.pickupRequest.pickupHomeTel = !!this.user.mobile2 ? convertNumbers2English(this.user.mobile2) : '';
      console.log('this.pickupRequest.pickupHomeTel', this.pickupRequest.pickupHomeTel);
      this.pickupRequest.shipmentType = 'Pickup';
      !!this.pickupRequest.notes ? this.pickupRequest.notes += ' - Pickup Request ' : this.pickupRequest.notes = 'Pickup Request ';
      this.pickupRequest.notes += ' - Number of shipments: ' + this.numberofshipments;
      this.pickupRequest.notesToPickupDriver ?  this.pickupRequest.notesToPickupDriver += ' - Number of shipments: ' + this.numberofshipments : this.pickupRequest.notesToPickupDriver = 'Number of shipments: ' + this.numberofshipments;
      this.pickupRequest.platform = 'web';
      this.pickupRequest.orderStatusId = '5dcebf66b754c26ac5785064'; //ObjectId("5dcebf66b754c26ac5785064")
      console.log(JSON.stringify(this.user));
      //this.pickupRequest.shippingPrice = !!this.user.pickupfees ? this.user.pickupfees : 0; //later on, get this from the user object
      //this.pickupRequest._receiverCustomer = wassalnow_account;
      this.pickupRequest.receiverCustomerId = '5d10a1bb08d64c56c54456ce';
      //if(this.paymentMethod === "Deduct"){
        this.pickupRequest.senderPays = this.pickupRequest.deductFromSender = true;
      //}
try {
      this.tripService.save(this.pickupRequest).subscribe(() => {
        
    this.setPage(0);
    this.togglePickupRequest();
      }, (err) => {
        console.error('err ==> ', err)
        alert('Error while creating pickup request');
      });
    } catch (e){
      console.log(e);
    }
    }
      catch(e){
console.log(e);
      }
    } else if (!this.pickupRequestDate){
      //show date is required
      console.log('No pickup date selected');
    }
}

addNewAddress() {
  this.submitted = true;
  if (!!this.user.address.address && !this.isAddressAlreadyExist(this.address)) {
    this.submitted = false;
    this.user.address = this.user.address || [];
    const cityName = this.cities.filter((city) => city.id = this.user.cityId);
    this.user.address.push({
      address: this.user.address.address,
      isDefault: false,
      location: {
        lat: this.lat,
        lng: this.lng
      },
      locationURL: this.locationURL,
      cityId: this.user.cityId,
      cityName: cityName.length > 0 ? cityName[0].name : ''
    });
    this.user.address.address = '';
    this.options = {} ;
    this.lat = defaultLocation.lat;
    this.lng = defaultLocation.lng;
    this.addressAlreadyExist = false;
    this.editingAddress = false;
  } else if (!this.user.address.address && !this.isAddressAlreadyExist(this.address)){
    this.submitted = true;
    this.show_dialog2 = false
  } else{
    this.addressAlreadyExist = true
    this.show_dialog2 = false
  }
  this.authService.save(this.user).subscribe()
  this.toggle2()
}

isPaymentMethodAlreadyExist(paymentMethod: any) {
  return (this.user.paymentMethods && this.user.paymentMethods.filter
      (pay =>
        (pay.paymentMethodName === paymentMethod.paymentMethodName && pay.accountNumber === paymentMethod.accountNumber)
      )
      .length > 0)
}

addNewPaymentMethod() {
  this.formHasError = false ;
  console.log(0)
  this.methods.forEach(method => {
    if (method.id === this.paymentMethod.paymentMethodId) {
      this.paymentMethod.paymentMethodName = method.name;
    }
  })
  if (!!this.paymentMethod.accountNumber
    && !!this.paymentMethod.paymentMethodName &&
    !!this.paymentMethod.paymentMethodId &&
    (!this.methodIsBank ||
    (!!this.methodIsBank && this.paymentMethod.bankId &&  this.paymentMethod.beneficiaryName))
    ) {
      if(this.isPaymentMethodAlreadyExist(this.paymentMethod)){
         this.paymentMethodAlreadyExist = true
        return
      }
    this.paymentMethods = this.paymentMethods || [];
    this.submitted = false;
    this.user.paymentMethods.push({
      id: uuid.v4(),
      paymentMethodId: this.paymentMethod.paymentMethodId,
      paymentMethodName: this.paymentMethod.paymentMethodName,
      accountNumber: this.paymentMethod.accountNumber,
      beneficiaryName : this.paymentMethod.beneficiaryName ,
      bank: this.banks.find(el => el.id === this.paymentMethod.bankId)
    });
    this.paymentMethod.paymentMethodName = '';
    this.paymentMethod.paymentMethodId = '';
    this.paymentMethod.accountNumber = null;
    this.paymentMethod.bankId = null  ;
    this.paymentMethod.beneficiaryName = null ;
    this.paymentMethodAlreadyExist = false;
    this.editingPaymentMethod = false;
    this.formHasError = false;
    this.methodIsBank=false;
  } else if (!this.paymentMethod.accountNumber && !this.paymentMethod.paymentMethodName && !this.paymentMethod.paymentMethodId && !this.isPaymentMethodAlreadyExist(this.paymentMethod)){
    console.log(2)
    this.submitted = true;
    this.show_dialog = false
  } else {
    console.log(3)

    this.show_dialog = false;
    this.formHasError = true
  }
  this.authService.save(this.user).subscribe()

  this.toggle()
}
onSelectMethod() {
  this.paymentMethod.bankId = null ;
  this.paymentMethod.beneficiaryName = null ;
   if (!!this.paymentMethod.paymentMethodId) {
     this.methodIsBank = !!this.methods.find(el => el.id === this.paymentMethod.paymentMethodId && !!el.type && el.type === 'Bank');
   } else {
     this.methodIsBank = false
   }
 }
 deleteAddress(address: any) {
    this.user.address = this.user.address.filter(add => add.address !== address.address);
    this.authService.save(this.user).subscribe()
  }
  deletepaymentMethod(paymentMethod: any) {
    this.user.paymentMethods = this.user.paymentMethods.filter(pay => pay.accountNumber !== paymentMethod.accountNumber);
    this.authService.save(this.user).subscribe()
  }
  creatOrder(){
    this.router.navigate(['/orders/create-order']).then();
 }
 generatePdf(order){

 }
 uploadFile(item: any) {
  console.log('upload started');
   this.uploadedFileName = item.file.name
  this.uploadResult = null;
  this.uploadError = null;
   if (this.authService.token.userId) {
    console.log('user exists');
     if(this.uploader.queue.length !== 0){
      console.log('this.uploader.queue.length !== 0');
      this.isFileUploading=true
       this.displayUploadModal = 'block';
     } else{
      this.uploadedFileName=null
     }
    item.upload();
    this.uploader.queue = [];
    this.uploadInputVariable.nativeElement.value = "";
  }
}
async onSuccessItem(item: FileItem, response: string, status: number) {
  this.isFileUploading=false
  this.uploadResult = JSON.parse(response);
  this.uploadError = null;
   this.setPage(0);
}

onErrorItem(item: FileItem, response: string, status: number){
  this.isFileUploading=false
  this.uploadResult = null;
  if(status&& status === 401){
    this.uploadError.message ='unauthorized'
  } else{
    this.uploadError = { message: 'فشل فى تحميل الشحنات' }
  }
  }
closeUploadModal(){
  this.displayUploadModal='none';
}
cancelOrder(order: any) {
  if (!this.authService.token) {
    return;
  }
  // this.userId = this.authService.token.userId;
  // this.authService.get(this.authService.token.userId).subscribe((res) => {
  //   this.user = res;
  // })
  var r = confirm("هل تريد إلغاء الشحنة؟");
  if(r){
    this.tripsServeices.cancelShipment(order.id).subscribe((res) => {
      console.log('soft delete success result: ' + JSON.stringify(res));
      if(JSON.stringify(res).toString() === "{\"result\":\"This shipment can not be deleted!\"}") {
        alert("لا يمكن إلغاء هذه الشحنة");
      } else {
        alert("تم إلغاء الشحنة بنجاح");
        this.router.navigate(['/orders']);
      }
    }, () => {
        alert("حدث خطأ أثناء إلغاء الشحنة!");
      });
  }
}

toggleBillPrint(orderid: any){
  if (this.printAWBTrackNos.includes(orderid)) {
    this.printAWBTrackNos.forEach( (item, index) => {
      if(item === orderid) this.printAWBTrackNos.splice(index,1);
    });
  } else {
    this.printAWBTrackNos.push(orderid);
  }
}

printMultipleAWB(){
  //this.printAWBTrackNos.forEach(orderid => {
    let stringofTracks = String(this.printAWBTrackNos);
    window.open(this.baseUrl+'/trips/generate-multiple-awb?id='+stringofTracks);
  //})
}

}
