import {BaseModel} from '../../_services/base-model';

export class Trip implements BaseModel {
  public id?: string;
  public pickupCustomer?: any = {notificationDevices: []};
  public receiverCustomer?: any = {notificationDevices: []};
  public pickupDriver? = {notificationDevices: []};
  public receiverDriver? = {notificationDevices: []};
  public vehicleId?: string;
  public shipmentType?: string;
  public documents?: any[];
  public driverImages?: any[];
  public startPoint?: string;
  public receiverPoint?: string;
  public estimatedPickupDate?: string;
  public estimatedPickupTime?: any;
  public estimatedDropDate?: string;
  public actualPickupDateTime?: string;
  public actualDropDateTime?: string;
  public actualAcceptDate?: string;
  public actualAcceptTime?: any;
  public rate?: string;
  public costBeforeDiscount?: string;
  public costAfterDiscount?: string;
  public shipmentPrice?: string;
  public discount?: string;
  public paymentMethod?: string;
  public comment?: string;
  public fullName?: string;
  public addDetails?: string;
  public receiverCity?: {name:''};
  public pickupCity?: {name:''};
  public mobile?: string;
  public receiverMobile ? : string;
  public platform?: string;
  public status?: string;
  public tripStatus?: string;
  public isDeleted ? = false;
  public trackNo?: number;
  public estimatedDropTime?: any;
  public vehicleOwnedByDriver?: boolean;
  public senderPays?: boolean;
  public notes?: string;
  public notesToDriver?: string;
  public notesToReceiverDriver?: string;
  public notesToPickupDriver?: string;
  public notesFromReceiverDriver?: string;
  public notesFromPickupDriver?: string;
  public pickupDriverId?: string;
  public receiverDriverId?: string;
  public shippingPrice?: number;
  public moneyCollected?: number;
  public orderStatusId?: string;
  public pickupCustomerId?: string;
  public pickupCustomerName? : string;
  public receiverCustomerId?: string;
  public pickupCityId?: string;
  public receiverCityId?: string;
  public paymentMethodId?: string;
  public record?: any;
  public pickupLocation?: {
    lat: number,
    lng: number
  };
  public pickUpLocationURL?: string;
  public destinationLocationURL?: string;
  public destinationLocation?: {
    lat: number,
    lng: number
  };
  notesByDriver?: string;
  public isCreatedByAdmin?: boolean = false;
  public shipmentContents?: string;
  public orderProgress?: string;
  public commStatus ?: string;
}
