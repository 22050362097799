import {Component, OnInit} from '@angular/core';
import { AuthenticationService } from 'src/app/_services/api/authentication.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-nav',
  templateUrl: './navbar.html',
  styleUrls: ['./navbar.scss']
})

export class navbarComponent implements OnInit {
  user: any = {};
  valid = true;
  token: any;
  isCollapsed = true;

  toggleNavbar() {
    this.isCollapsed = !this.isCollapsed;
  }

  constructor(public auth: AuthenticationService,
              private router: Router) {
    }

 ngOnInit() {
  this.auth.me().subscribe(user => {
    this.user = user;
    this.checkAuth()
  });
 }

 logOut(){
   this.auth.logOut();
   this.checkAuth();
   this.router.navigate(['/login']).then()
 }

 checkAuth() {
   this.valid = this.auth.isAuthenticated();
 }

 logIn(){
  this.router.navigate(['/login']).then();
 }
 getTrans(){
    this.router.navigate(['/financial']).then();
 }
 viewTrans(){
   this.router.navigate(['/financial']);
 }
 viewCharts(){
   this.router.navigate(['/charts']);
 }
 viewPaymentRequest(){
   this.router.navigate(['/payment-request']);
 }
 creatOrder(){
   this.router.navigate(['/orders/create-order']).then();
}
viewReturns(){
  this.router.navigate(['/returns']);
}
}

