import { AuthenticationService } from './_services/api/authentication.service';
import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { CitiesApi } from './_services/api/city.service';

import { AppRoutingModule } from './app-routing.module';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { UsersApi } from './_services/api/user.api';
import { UserService } from './_services/api/users.service';
import {TripsApi} from './_services/api/trips.service';
import {HttpClientModule} from "@angular/common/http";
import { OrdersModule } from './orders/orders.module';
import { AuthGuard } from './_services/auth/auth-guard.service';
import { AuthService } from './_services/auth/auth.service';
import { FinancialComponent } from './customer-financials/customer-financials.component';
import {Ng2SmartTableModule} from 'ng2-smart-table';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import { TransactionHistoryApi } from './_services/api/transactionHistory.service';
import { SharedModule } from './sharedModule';
import { BookTripService } from './_services/api/book-trip.service';
import { VacationsService } from './_services/api/vacations.service';
import { TripUrlComponent } from './tripUrl/tripUrl.component';
import { TripUrlModule } from './tripUrl/tripUrl.module';
import { AgmCoreModule } from '@agm/core';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { UserPaymentsApi } from './_services/api/userPayments.service';
import { ChartsComponent } from './charts/charts.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { PaymentComponent } from './payment/payment.component';
import { ReturnsComponent } from './returns/returns.component';
import { SignupComponent } from './signup/signup.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    FinancialComponent,
    TripUrlComponent,
    ChartsComponent,
    PaymentComponent,
    ReturnsComponent,
    SignupComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    CommonModule,
    FormsModule,
    OrdersModule,
    NgxDatatableModule,
    Ng2SmartTableModule,
    SharedModule,
    AgmCoreModule,
    GooglePlaceModule,
    TripUrlModule,
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    NgxChartsModule,
  ],
  providers: [
    AuthenticationService,
    BookTripService,
    CitiesApi,
    UsersApi,
    UserService,
    TripsApi,
    AuthGuard,
    AuthService,
    TransactionHistoryApi,
    VacationsService,
    UserPaymentsApi
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
