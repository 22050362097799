import {Injectable} from '@angular/core';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/Rx';
import { User } from '../../shared/models/user';
import { environment } from './../../../environments/environment';
import { map } from 'rxjs/operators';
import { BaseApiService } from '../baseAPI';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AuthenticationService extends BaseApiService<any> {

  token: {
    userId: string;
    id: string;
  };

  constructor(http: HttpClient) {
    super(http);
    this.url = '/users';
    this.token = JSON.parse(localStorage.getItem('tokenProtal') || '{}');
  }

  isAuthenticated() {
    this.token = JSON.parse(localStorage.getItem('tokenProtal'));
    return !!this.token
  }

  async addNewUser(user: any) {
    await this.add(user).toPromise();
  }

  me() {
    return this.get(this.token.userId)
  }

   login(username: string, password: string) {
    return this.http.post(environment.baseUrl + this.url + '/login', {
      username: username,
      password: password,
      ttl: 365 * 24 * 60 * 60 * 1000
    }).pipe(map((response: { userId: string; id: string; }) => {
      this.token = response;
      if (this.token && this.token.id) {
        localStorage.setItem('tokenProtal', JSON.stringify(this.token));
      }
      return this.token;
    })).toPromise()
  }

  VerifyCode(user) {
    return this.http.post(environment.baseUrl + this.url + '/phone/confirm', user)
      .pipe(map((response: { userId: string; id: string; }) => {
        this.token = response;
        if (this.token && this.token.id) {
          localStorage.setItem('tokenProtal', JSON.stringify(this.token));
        }
        return this.token;
      }));
  }

  sendCode(mobile) {
    return this.http.post<any>(
      environment.baseUrl + this.url + '/phone/resend', {mobile: mobile});
  }

  checkUserVerification(mobile) {
    return this.http.get<any>(
      environment.baseUrl + this.url + '/checkUserVerification?mobile=' + mobile);
  }

  sendRecoverPasswordCode(mobile) {
    return this.http.post<any>(
      environment.baseUrl + this.url + '/phone/forget-password', {mobile: mobile});
  }

  recoverPassword(mobile, vCode, password) {
    return this.http.post<any>(
      environment.baseUrl + this.url + '/phone/set-password', {mobile: mobile, vCode: vCode, password: password});
  }

  logOut() {
    localStorage.removeItem('tokenProtal');
    this.token = null;
  }

  saveNotificationDevices(user: User) {
    let userSaving = {
      id: user.id,
      notificationDevices: user.notificationDevices
    };
    console.log('userSaving ==> ', userSaving)
    return this.http.put(environment.baseUrl + this.url + '/updateNotificationDevices', userSaving, {headers: this.authorization()})
  }
}
