import { TripsApi } from '../_services/api/trips.service';
/// <reference path="../../../../node_modules/@angular/core/src/metadata/lifecycle_hooks.d.ts"/>
import {Component, OnInit} from '@angular/core';
import {Page} from '../_services/page';
import {ActivatedRoute, Router} from '@angular/router';
import { AuthenticationService } from '../_services/api/authentication.service';

@Component({
  selector: 'app-returns',
  templateUrl: './returns.component.html',
  styleUrls: ['./returns.component.scss']
})
export class ReturnsComponent implements OnInit {

  public rows = [];
  public page = new Page();
  user: any;

  constructor(private tripsAPI: TripsApi,
              private route: ActivatedRoute,
              private router: Router,
              private auth : AuthenticationService) { 
                this.setPage({offset: 0});
              }

  ngOnInit(): void {
  }

  async setPage(pageInfo) {
    this.user = await this.auth.me().toPromise();
    this.page.pageNumber = pageInfo.offset;
    this.page.order = ['created desc'];
    this.page.size = 50;
    this.page.where = {
      and: [
        {
          isDeleted: {neq: true}
        },
        {
            receiverCustomerId: this.user.id
        }
      ]
    },
    this.tripsAPI.query(this.page)
      .subscribe(pagedData => {
        this.rows = pagedData.data;
        pagedData['size'] = 50;
        this.page = new Page(pagedData)
      });
      console.log(this.page);
  }

}
