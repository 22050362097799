import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Ng2SmartTableModule} from 'ng2-smart-table';
import {FormsModule} from '@angular/forms';
import {UserService} from '../_services/api/users.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { TripUrlRoutingModule } from './tripUrl-routing.module';
import { SharedModule } from '../sharedModule';
import { TripUrlResolver } from './tripUrl-resolver';
import { TripsApi } from '../_services/api/trips.service';
import {AgmCoreModule} from '@agm/core';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { environment } from '../../environments/environment.prod';

@NgModule({
  imports: [
    CommonModule,
    TripUrlRoutingModule,
    Ng2SmartTableModule,
    FormsModule,
    NgSelectModule,
    AgmCoreModule.forRoot({
      apiKey: environment.googleApiKey
    }),
    GooglePlaceModule,
    SharedModule,
  ],
  declarations: [
  ],
  providers: [
    UserService,
    TripUrlResolver,
    TripsApi,
  ]
})
export class TripUrlModule {
}
