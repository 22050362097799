import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';


import {BaseApiService} from '../baseAPI';
import { UserPayment } from '../../shared/models/userPayment';


@Injectable()
export class UserPaymentsApi extends BaseApiService<UserPayment> {

  constructor(http: HttpClient) {
    super(http);
    this.url = '/userPayments';
  }

}