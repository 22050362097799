import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';


import {BaseApiService} from '../baseAPI';
import { CustomerFinancials } from '../../shared/models/CustomerFinancials';


@Injectable()
export class TransactionHistoryApi extends BaseApiService<CustomerFinancials> {

  constructor(http: HttpClient) {
    super(http);
    this.url = '/userTransactionsHistories';
  }

}


