import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {OrdersRoutingModule} from './orders-routing.module';

import {OrdersComponent} from '../orders/orders.component';

import {OrderResolver} from './order-resolver';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TripsApi} from '../_services/api/trips.service';
import {UsersApi} from '../_services/api/user.api';
import {CitiesApi} from '../_services/api/city.service';
import {PaymentMethodsApi} from '../_services/api/paymentMethods.service';
import {DriversApi} from '../_services/api/drivers.service';
import {OrderStatusesApi} from '../_services/api/orderStatuses.service';
import { OrderDetailsComponent } from './orders-details/orders-details.component';
import { SharedModule } from '../sharedModule';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { CreateOrderComponent } from './create-order/create-order.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FileSelectDirective } from 'ng2-file-upload';
import { BanksApi } from '../_services/api/banks.service';
import { FileUploadModule } from 'ng2-file-upload';


@NgModule({
  imports: [
    CommonModule,
    OrdersRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    NgSelectModule,
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    FileUploadModule
  ],
  declarations: [
    OrdersComponent,
    OrderDetailsComponent,
    CreateOrderComponent
  ],
  providers: [
    TripsApi,
    UsersApi,
    OrderResolver,
    CitiesApi,
    DriversApi,
    OrderStatusesApi,
    PaymentMethodsApi,
    TripsApi,
    UsersApi,
    OrderResolver,
    BanksApi
  ]
})
export class OrdersModule {
}
