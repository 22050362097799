import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseApiService} from '../baseAPI';
import { OrderStatus } from '../../shared/models/orderStatus';

@Injectable()
export class OrderStatusesApi extends BaseApiService<OrderStatus> {

  constructor(http: HttpClient) {
    super(http);
    this.url = '/orderStatuses';
  }
}


