import { TransactionHistoryApi } from '../_services/api/transactionHistory.service';
/// <reference path="../../../../node_modules/@angular/core/src/metadata/lifecycle_hooks.d.ts"/>
import {Component, OnInit} from '@angular/core';
import {Page} from '../_services/page';
import {ActivatedRoute, Router} from '@angular/router';
import { AuthenticationService } from '../_services/api/authentication.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-customer-financials',
  templateUrl: './customer-financials.component.html',
  styleUrls: ['./customer-financials.component.scss']
})

export class FinancialComponent implements OnInit {

  public rows = [];
  public page = new Page();
  user: any;
  showModal = false;
  modalElement: any;
  isTrip = false;
  csvDateFrom = new Date() ;
  csvDateTo  =new Date() ;
  CSVaAll = true
  displayExportModal='none';

  constructor(private transactionService: TransactionHistoryApi,
              private route: ActivatedRoute,
              private router: Router,
              private auth : AuthenticationService) {
                this.setPage({offset: 0});
              }

  ngOnInit(): void {
    const that = this;
    window.onclick = function (event) {
      if (event.target['id'] === 'myModal') {
        that.showModal = false;
      }
    };
  }


  async setPage(pageInfo) {
    this.user = await this.auth.me().toPromise();
    this.page.pageNumber = pageInfo.offset;
    this.page.order = ['created desc'];
    this.page.size = 50;
    this.page.where = {userId: this.user.id};
    this.transactionService.query(this.page)
      .subscribe(pagedData => {
        this.rows = pagedData.data;
        pagedData['size'] = 50;
        this.page = new Page(pagedData)
      })
  }

  openModal(element: any) {
    this.showModal = true;
    this.modalElement = element;
    console.log('modal element', element);
  }

  closeModal() {
    this.showModal = false;
  }

  showModel(value, row) {
    if (!value) {
      return;
    }
    this.modalElement = value;
    if (row['type'] === 'Order') {
      this.isTrip = true;
    } else {
      this.isTrip = false;
    }
    setTimeout(() => {
        this.openModal(value);
    }, 250)
  }
  openCsvModal() {
    this.displayExportModal = 'block';

  }
  closeCsvModal(){
    this.displayExportModal = 'none';

  }
  getCsvFile(){
    const filter : any = {where: {and : [ {userId:this.user.id} ,{isDeleted :{neq : true}}]} , include : ['user']}
    if(!this.CSVaAll){
     filter.where.and.push({created:{gte: this.csvDateFrom}} , {created:{lte: this.csvDateTo}})
    }
    window.open(environment.baseUrl + '/userTransactionsHistories'+'/csv?filter='+JSON.stringify(filter));
  }
}
