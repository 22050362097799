import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { BaseApiService } from '../baseAPI';
import { Vacation } from 'src/app/shared/models/vacation';

@Injectable()
export class VacationsService extends BaseApiService<Vacation> {
  constructor(http: HttpClient) {
    super(http);
    this.url = '/vacations';
  }
}
