import { Component, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { TripsApi } from '../_services/api/trips.service';
import { AuthenticationService } from '../_services/api/authentication.service';


@Component({
  selector: 'app-charts',
  templateUrl: './charts.component.html',
  styleUrls: ['./charts.component.scss']
})
export class ChartsComponent implements OnInit {

  lastWeek = moment().subtract(6, 'days').toDate();
  lastMonth = moment().subtract(29, 'days').toDate();
  isWeekly = true;
  isDeliveryWeekly = true;
  barChartView: any[] =  [550, 400];
  barChartShowYAxis = true;
  barChartShowXAxis = true;
  barChartGradient = false;
  barChartShowLegend = false;
  barChartShowXAxisLabel = true;
  barChartXAxisLabel = 'Days';
  barChartShowYAxisLabel = true;
  barChartYAxisLabel = 'Count';
  barChartColorScheme = {
    domain: ['#009DA0', '#FF8D60', '#FF586B', '#AAAAAA' , '#C0546A', '#CAFE00', '#F00']
  };
  pieChartView: any[] = [550, 400];
  pieChartShowLegend = false;
  pieChartColorScheme = {domain: ['#009DA0', '#FF8D60', '#C0546A', '#CAFE00']};
  pieChartShowLabels = true;
  pieChartExplodeSlices = false;
  pieChartDoughnut = true;
  pieChartGradient = false;
  pieChart1ExplodeSlices = true;
  pieChart1Doughnut = false;
  dailyShipments = [];
  user: any;
  userincome: any;
  returnCount: any;
  deliveredCount: any;
  successRate: any;



  constructor(
              private tripService: TripsApi,
              private auth : AuthenticationService
    ) { 
  }

  async setUser(){
    this.user = await this.auth.me().toPromise();
  }

  ngOnInit() {
    this.setUser();
    this.refreshStats('weekly');
  }



  async onGetStat(type: string) {
    this.user = await this.auth.me().toPromise();
    console.log('this.user', this.user);
    this.tripService.userdailydeliverystatistics(type === 'monthly' ? this.lastMonth : this.lastWeek, this.user.id).subscribe(res => {
      this.isWeekly = type === 'weekly' ? true : false;
      if (res && res['daily_trips'] && res['daily_trips'].length > 0) {
        this.dailyShipments = res['daily_trips'].map(trip => {
          trip.name = moment(trip.name).format('ddd DD-MM-YYYY');
          return trip;
        });
      }
    })
  }

  refreshStats(type: string) {
    this.onGetStat(type);
    this.onGetIncome(type);
    this.onGetSuccessRate(type);
  }

  async onGetSuccessRate(type: string) {
    this.user = await this.auth.me().toPromise();
    console.log('this.user', this.user);
    this.tripService.getDeliveriesReturnsCount(this.user.id, type === 'monthly' ? this.lastMonth : this.lastWeek).subscribe(res => {
      this.isWeekly = type === 'weekly' ? true : false;
      if (res) {
        console.log(JSON.stringify(res));
        this.deliveredCount = !!res[0].Delivered ? res[0].Delivered : 0;
        this.returnCount = !!res[0].Returns ? res[0].Returns : 0;

        this.successRate =  (this.deliveredCount + this.returnCount) > 0 ? Math.round((this.deliveredCount / (this.deliveredCount + this.returnCount)) * 100) : 100;
        
        // let text1 = document.getElementById('0101');
        // this.animate(text1, 0, this.userincome, 800);
      }
    })
  }
  async onGetIncome(type: string) {
    this.user = await this.auth.me().toPromise();
    console.log('this.user', this.user);
    this.tripService.getUserIncomebyInterval(this.user.id, type === 'monthly' ? this.lastMonth : this.lastWeek).subscribe(res => {
      this.isWeekly = type === 'weekly' ? true : false;
      if (res) {
        console.log(JSON.stringify(res));
        this.userincome = res[0].totalBalance;
        
        // let text1 = document.getElementById('0101');
        // this.animate(text1, 0, this.userincome, 800);
      }
    })
  }



  animate(obj, initVal, lastVal, duration) {

    let startTime = null;

    //get the current timestamp and assign it to the currentTime variable

    let currentTime = Date.now();

    //pass the current timestamp to the step function

    const step = (currentTime ) => {

    //if the start time is null, assign the current time to startTime

        if (!startTime) {
        startTime = currentTime ;
        }

    //calculate the value to be used in calculating the number to be displayed

        const progress = Math.min((currentTime  - startTime) / duration, 1);

    //calculate what to be displayed using the value gotten above

        obj.innerHTML = Math.floor(progress * (lastVal - initVal) + initVal);

    //checking to make sure the counter does not exceed the last value(lastVal)

        if (progress < 1) {
            window.requestAnimationFrame(step);
        }
    };

    //start animating
    window.requestAnimationFrame(step);

  }

}
