import {Component, OnInit, ViewChild, Input} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import { TripsApi } from '../_services/api/trips.service';
import { Trip } from '../shared/models/trip';
import {GooglePlaceDirective} from 'ngx-google-places-autocomplete';
import {Address} from 'ngx-google-places-autocomplete/objects/address';

@Component({
  selector: 'app-tripUrl',
  templateUrl: './tripUrl.component.html',
  styleUrls: ['./tripUrl.component.scss']
})
export class TripUrlComponent {

  @ViewChild('placesRef', {static: false}) placesRef: GooglePlaceDirective;

  tripData: Trip;
  defaultAddress: string;
  location: any ={};
  newAddressAdded: string;
  sendSuccessfully: boolean = false
  locationChanged: boolean = false
  sendFalid: boolean = false;
  lat: number ;
  lng: number ;
  locationURL: any = `http://maps.google.com/?q=${this.lat},${this.lng}`;
  options: any = {};
  myRegexp = /@(\-?[0-9]+\.[0-9]+),(\-?[0-9]+\.[0-9]+)/;
  saving = false ;
  constructor(
    private tripsServeices: TripsApi,
    private route: ActivatedRoute
    ) {
      this.tripData = this.route.snapshot.data.trip as Trip;
      if(!!this.tripData && !this.tripData.receiverPoint &&!!this.tripData.receiverCustomer.address && this.tripData.receiverCustomer.address.length > 0){
       let address = this.tripData.receiverCustomer.address.find(addrs=>addrs.isDefault);
       if(address) {
         this.defaultAddress = address.address ? address.address : null;
         this.locationURL = address.locationURL?address.locationURL : null;
         if(!!address.location && !(address.location.lat == 37.97589393307724 && address.location.lng == 23.66287293749997)){
         this.lat = address.location.lat;
         this.lng = address.location.lng;
         }
         this.location = address.location ? address.location : null
         this.tripsServeices.saveReceiverPoint(this.tripData.id, this.defaultAddress, this.locationURL, this.location).subscribe(() =>{})
       }
      } else if (!!this.tripData && !!this.tripData.receiverPoint){
        this.defaultAddress = this.tripData.receiverPoint;
        if(this.tripData.destinationLocation &&
          this.tripData.destinationLocation.lat &&
          this.tripData.destinationLocation.lng){
            this.lat = this.tripData.destinationLocation.lat;
            this.lng = this.tripData.destinationLocation.lng;
          }
      }
      if(this.lat=== 37.97589393307724 && this.lng === 23.66287293749997){
        this.lat = null ;
        this.lng = null;
      }
      if (navigator && !this.lat && !this.lng){
        navigator.geolocation.getCurrentPosition( pos => {
          this.lat = pos.coords.latitude;
          this.lng = pos.coords.longitude;
        });
      }
      if(!this.lat && !this.lng){
        this.lat =  30.047847;
        this.lng= 31.23364;
      }
      }

    handleAddressChange(address: Address) {
      this.lat = address.geometry.location.lat(),
      this.lng = address.geometry.location.lng()
      this.locationURL = `http://maps.google.com/?q=${this.lat},${this.lng}`;
    }
    setLocation(event: any) {
      this.lat = event.coords.lat;
      this.lng = event.coords.lng;
      this.locationURL = `http://maps.google.com/?q=${this.lat},${this.lng}`;
      this.locationChanged = true
    }
  async AddNewAddress(ngForm: any) {
    this.location = {
      lat: this.lat,
      lng: this.lng
    }
    if (this.defaultAddress && ngForm && ngForm.valid && this.isAddressChange()) {
      try {
        this.saving =true ;
        await this.tripsServeices.saveReceiverPoint(this.tripData.id, this.defaultAddress, this.locationURL, this.location).toPromise();
        this.tripData.receiverPoint = this.defaultAddress ;
        if(this.location) this.tripData.destinationLocation= this.location;
        this.sendSuccessfully = true
        setTimeout(() => {
          this.sendSuccessfully = false
        }, 2000);
      } catch (error) {
        console.error(error);
        this.sendFalid = true
        setTimeout(() => {
          this.sendFalid = false
        }, 2000);
      }
      finally {
        this.saving  = false ;
      }
    }

  }
  private isAddressChange() {
    if(this.lat ===  30.047847 && this.lng === 31.23364){
      console.log("Egy Museum");
      return false;
    } else
    if (!this.tripData.receiverPoint)
      return true
    else if ((this.tripData.receiverPoint !== this.defaultAddress))
      return true
    else if (!this.tripData.destinationLocation && this.location)
      return true
    else if (this.location) {
      if (this.location.lat && this.location.lat !== this.tripData.destinationLocation.lat)
      return true
      if (this.location.lng && this.location.lng !== this.tripData.destinationLocation.lng)
        return true
      return false

    } else {
      return false
    }
  }
}
