import { environment } from './../../../environments/environment';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Trip} from '../../shared/models/trip';
import {Location} from '@angular/common';
import {Router} from '@angular/router';
import * as moment from 'moment';
import {TripsApi} from "../../_services/api/trips.service";
import { AuthenticationService } from '../../_services/api/authentication.service';

@Component({
  selector: 'app-trips',
  templateUrl: './orders-details.component.html',
  styleUrls: ['./orders-details.component.scss']
})

export class OrderDetailsComponent implements OnInit {
  trip: any;
  url :string;
  userId: any;
  user: any;
  orderProgress = "";
  replyText = "";

  constructor(
    private router: Router,
    private tripsServeices: TripsApi,
    private route: ActivatedRoute,
    private location: Location,
    private authService: AuthenticationService
    ) {
  }

    ngOnInit() {
      this.authService.me().subscribe((user) => {
        this.user = user;
      });

     this.trip = this.route.snapshot.data.order as Trip;
      if (!this.trip) {
        this.goBack();
        return;
      }
     if (!!this.trip.id) {
        if (!moment(this.trip.estimatedDropTime).isValid()) {
          if (moment(this.trip.estimatedDropTime, 'hh:mm').isValid()) {
            this.trip.estimatedDropTime = moment(this.trip.estimatedDropTime, 'hh:mm').format('YYYY-MM-DD hh:mm a');
          } else {
            this.trip.estimatedDropTime = null;
          }
        }
        if (!moment(this.trip.estimatedPickupTime).isValid()) {
          if (moment(this.trip.estimatedPickupTime, 'hh:mm').isValid()) {
            this.trip.estimatedPickupTime = moment(this.trip.estimatedPickupTime, 'hh:mm').format('YYYY-MM-DD hh:mm a');
          } else {
            this.trip.estimatedPickupTime = null;
          }
        }

        if (!moment(this.trip.actualPickupDateTime).isValid()) {
          if (moment(this.trip.actualPickupDateTime, 'hh:mm').isValid()) {
            this.trip.actualPickupDateTime = moment(this.trip.actualPickupDateTime, 'hh:mm').format('YYYY-MM-DD hh:mm a');
          } else {
            this.trip.actualPickupDateTime = null;
          }
        }

        if (!moment(this.trip.actualDropDateTime).isValid()) {
          if (moment(this.trip.actualDropDateTime, 'hh:mm').isValid()) {
            this.trip.actualDropDateTime = moment(this.trip.actualDropDateTime, 'hh:mm').format('YYYY-MM-DD hh:mm a');
          } else {
            this.trip.actualDropDateTime = null;
          }
        }
        this.url= environment.baseUrl;
        this.updateOrderProgress();
        var orderProgresstextArea = document.getElementById('orderProgress');
        orderProgresstextArea.scrollTop = orderProgresstextArea.scrollHeight;
    }
  }
  updateOrderProgress(){
    this.orderProgress = "";
    if(!!this.trip.orderProgress)
    {
      for (let historyItem of this.trip.orderProgress) {
      this.orderProgress += historyItem.date + '\n';
      this.orderProgress += historyItem.updateText + '\n';
      }
    }
  }

  goBack() {
    this.location.back();
  }
  cancelOrder() {
    if (!this.authService.token) {
      return;
    }
    // this.userId = this.authService.token.userId;
    // this.authService.get(this.authService.token.userId).subscribe((res) => {
    //   this.user = res;
    // })
    var r = confirm("هل تريد إلغاء الشحنة؟");
    if(r){
      this.tripsServeices.cancelShipment(this.trip.id).subscribe((res) => {
        console.log('soft delete success result: ' + JSON.stringify(res));
        if(JSON.stringify(res).toString() === "{\"result\":\"This shipment can not be deleted!\"}") {
          alert("لا يمكن إلغاء هذه الشحنة");
        } else {
          alert("تم إلغاء الشحنة بنجاح");
          this.router.navigate(['/orders']);
        }
      }, () => {
          alert("حدث خطأ أثناء إلغاء الشحنة!");
        });
    }
  }


  sendReply() {
    console.log(this.replyText);  // Echo the value of the input field
    var currentMoment = moment(new Date(), 'hh:mm').format('YYYY-MM-DD hh:mm a');
    let senderName = 'Customer';
    if(!!this.user && !!this.user.fullName) {
      senderName = this.user.fullName;
    }

    const logItem = {
      date : currentMoment,
      updateText :  senderName + ' Reply: ' + this.replyText
    }
    
    if(!!this.trip.orderProgress){
      this.trip.orderProgress = this.trip.orderProgress;
      this.trip.orderProgress.push(logItem);
    } else {
      this.trip.orderProgress = [logItem];
    };
    this.trip.commStatus = "مطلوب التواصل مع المرسل";
    
    this.updateOrderProgress();
    this.replyText = '';  // Clear the input field

    this.tripsServeices.save(this.trip).subscribe(() => {
      console.log('Reply Saved successfully!');
    }, error => {
      console.error('error ===>', error);
    });
  }
}



























