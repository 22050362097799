import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';

import { Trip } from '../shared/models/trip';
import { TripsApi } from '../_services/api/trips.service';

@Injectable()
export class TripUrlResolver implements Resolve<any> {


    constructor(private api: TripsApi) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Trip> | Promise<Trip> | Trip {
        const dropStatus = [
          '5ae9d64d4cb3dd109851096d',
          '5ae9d64d4cb3dd109851056d',
          '5c7657eedae9da62293ff812'
        ];
        const id = route.paramMap.get('id');
        if (!id) {
          return null;
        }
        return this.api.query(
          {where: {and: [{id}, {orderStatusId: {nin: dropStatus}}, {isDeleted: {neq: true}}]}, include: ['pickupDriver']}
          ).pipe(
            map(trips => {
              if (!trips || !trips.data || trips.data.length === 0) {
                return null;
              }
              return trips.data[0];
            })
        );
    }

}
