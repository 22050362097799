import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';


import {BaseApiService} from '../baseAPI';
import { City } from '../../shared/models/city';


@Injectable()
export class CitiesApi extends BaseApiService<City> {

  constructor(http: HttpClient) {
    super(http);
    this.url = '/cities';
  }

}


