import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs/Rx';

import 'rxjs/add/operator/catch';
import { AuthenticationService } from '../api/authentication.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private _router: Router, private authService: AuthenticationService) {
  }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {

    const loginRoute = '/login';
    if (this.authService.isAuthenticated()) {
      return true;
    } else {
      this._router.navigate([loginRoute], {queryParams: {returnUrl: state.url}});
      return false;
    }
  }
}
