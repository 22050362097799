import {Component, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import { AuthenticationService } from '../_services/api/authentication.service';
import {convertNumbers2English} from "../shared/utils/utils";
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @ViewChild('f',{static: false}) loginForm: NgForm;
  returnUrl = '';
  wrongCredintials: boolean = false;

  constructor( private router: Router,
    private auth: AuthenticationService,
    ) { }

  ngOnInit() {
  }
  async onSubmit(ngForm) {
    try {
    let res= await this.auth.checkUserVerification(convertNumbers2English(ngForm.value.phone)).toPromise();
    if (ngForm.valid && res.isVerified) {
      await this.auth.login(ngForm.value.phone, ngForm.value.password)
      this.loginForm.reset();
      this.router.navigate(['/orders']);
    }else {
      this.wrongCredintials = true;
      console.log("this.wrongCredintials", this.wrongCredintials);
    }
  } catch (err){
    console.log(err);
    this.wrongCredintials = true;
    console.log("this.wrongCredintials", this.wrongCredintials);
  }

  }
  onForgotPassword(){

  }
  onRegister(){

  }
}
